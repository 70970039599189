export const UiAccordion = () => import('../../components/UI/UiAccordion.vue' /* webpackChunkName: "components/ui-accordion" */).then(c => wrapFunctional(c.default || c))
export const UiAccordionItem = () => import('../../components/UI/UiAccordionItem.vue' /* webpackChunkName: "components/ui-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const UiBreadcrumbs = () => import('../../components/UI/UiBreadcrumbs.vue' /* webpackChunkName: "components/ui-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const UiButton = () => import('../../components/UI/UiButton.vue' /* webpackChunkName: "components/ui-button" */).then(c => wrapFunctional(c.default || c))
export const UiCheckbox = () => import('../../components/UI/UiCheckbox.vue' /* webpackChunkName: "components/ui-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiFilter = () => import('../../components/UI/UiFilter.vue' /* webpackChunkName: "components/ui-filter" */).then(c => wrapFunctional(c.default || c))
export const UiInput = () => import('../../components/UI/UiInput.vue' /* webpackChunkName: "components/ui-input" */).then(c => wrapFunctional(c.default || c))
export const UiInputWrapper = () => import('../../components/UI/UiInputWrapper.vue' /* webpackChunkName: "components/ui-input-wrapper" */).then(c => wrapFunctional(c.default || c))
export const UiLazyImg = () => import('../../components/UI/UiLazyImg.vue' /* webpackChunkName: "components/ui-lazy-img" */).then(c => wrapFunctional(c.default || c))
export const UiLazyVideo = () => import('../../components/UI/UiLazyVideo.vue' /* webpackChunkName: "components/ui-lazy-video" */).then(c => wrapFunctional(c.default || c))
export const UiMenu = () => import('../../components/UI/UiMenu.vue' /* webpackChunkName: "components/ui-menu" */).then(c => wrapFunctional(c.default || c))
export const UiNumberInput = () => import('../../components/UI/UiNumberInput.vue' /* webpackChunkName: "components/ui-number-input" */).then(c => wrapFunctional(c.default || c))
export const UiPagination = () => import('../../components/UI/UiPagination.vue' /* webpackChunkName: "components/ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const UiRadio = () => import('../../components/UI/UiRadio.vue' /* webpackChunkName: "components/ui-radio" */).then(c => wrapFunctional(c.default || c))
export const UiScrollableSlider = () => import('../../components/UI/UiScrollableSlider.vue' /* webpackChunkName: "components/ui-scrollable-slider" */).then(c => wrapFunctional(c.default || c))
export const UiSelect = () => import('../../components/UI/UiSelect.vue' /* webpackChunkName: "components/ui-select" */).then(c => wrapFunctional(c.default || c))
export const UiSpinner = () => import('../../components/UI/UiSpinner.vue' /* webpackChunkName: "components/ui-spinner" */).then(c => wrapFunctional(c.default || c))
export const UiSwiperCarousel = () => import('../../components/UI/UiSwiperCarousel.vue' /* webpackChunkName: "components/ui-swiper-carousel" */).then(c => wrapFunctional(c.default || c))
export const UiTextArea = () => import('../../components/UI/UiTextArea.vue' /* webpackChunkName: "components/ui-text-area" */).then(c => wrapFunctional(c.default || c))
export const BlocksArticlesBlock = () => import('../../components/blocks/ArticlesBlock.vue' /* webpackChunkName: "components/blocks-articles-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksBestSellingBlock = () => import('../../components/blocks/BestSellingBlock.vue' /* webpackChunkName: "components/blocks-best-selling-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksContainer = () => import('../../components/blocks/BlocksContainer.vue' /* webpackChunkName: "components/blocks-container" */).then(c => wrapFunctional(c.default || c))
export const BlocksCatalogBlock = () => import('../../components/blocks/CatalogBlock.vue' /* webpackChunkName: "components/blocks-catalog-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksCategoriesBlock = () => import('../../components/blocks/CategoriesBlock.vue' /* webpackChunkName: "components/blocks-categories-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksComparisonBlock = () => import('../../components/blocks/ComparisonBlock.vue' /* webpackChunkName: "components/blocks-comparison-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksEntityBlock = () => import('../../components/blocks/EntityBlock.vue' /* webpackChunkName: "components/blocks-entity-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksInstagramBlock = () => import('../../components/blocks/InstagramBlock.vue' /* webpackChunkName: "components/blocks-instagram-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksMarketingBlock = () => import('../../components/blocks/MarketingBlock.vue' /* webpackChunkName: "components/blocks-marketing-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksMarketingCollectionsBlock = () => import('../../components/blocks/MarketingCollectionsBlock.vue' /* webpackChunkName: "components/blocks-marketing-collections-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksPromotedBlock = () => import('../../components/blocks/PromotedBlock.vue' /* webpackChunkName: "components/blocks-promoted-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksQuestionFormBlock = () => import('../../components/blocks/QuestionFormBlock.vue' /* webpackChunkName: "components/blocks-question-form-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksRecentlyViewedBlock = () => import('../../components/blocks/RecentlyViewedBlock.vue' /* webpackChunkName: "components/blocks-recently-viewed-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksRelatedProductBlock = () => import('../../components/blocks/RelatedProductBlock.vue' /* webpackChunkName: "components/blocks-related-product-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksRichTextBlock = () => import('../../components/blocks/RichTextBlock.vue' /* webpackChunkName: "components/blocks-rich-text-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksSalesBlock = () => import('../../components/blocks/SalesBlock.vue' /* webpackChunkName: "components/blocks-sales-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksSharedCatalogBlock = () => import('../../components/blocks/SharedCatalogBlock.vue' /* webpackChunkName: "components/blocks-shared-catalog-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksTopSliderBlock = () => import('../../components/blocks/TopSliderBlock.vue' /* webpackChunkName: "components/blocks-top-slider-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksWishlistBlock = () => import('../../components/blocks/WishlistBlock.vue' /* webpackChunkName: "components/blocks-wishlist-block" */).then(c => wrapFunctional(c.default || c))
export const CommonAppFooter = () => import('../../components/common/AppFooter.vue' /* webpackChunkName: "components/common-app-footer" */).then(c => wrapFunctional(c.default || c))
export const CommonArticleCard = () => import('../../components/common/ArticleCard.vue' /* webpackChunkName: "components/common-article-card" */).then(c => wrapFunctional(c.default || c))
export const CommonCardImageView = () => import('../../components/common/CardImageView.vue' /* webpackChunkName: "components/common-card-image-view" */).then(c => wrapFunctional(c.default || c))
export const CommonCatalogList = () => import('../../components/common/CatalogList.vue' /* webpackChunkName: "components/common-catalog-list" */).then(c => wrapFunctional(c.default || c))
export const CommonCollectionCard = () => import('../../components/common/CollectionCard.vue' /* webpackChunkName: "components/common-collection-card" */).then(c => wrapFunctional(c.default || c))
export const CommonEntityCard = () => import('../../components/common/EntityCard.vue' /* webpackChunkName: "components/common-entity-card" */).then(c => wrapFunctional(c.default || c))
export const CommonFooter4Col = () => import('../../components/common/Footer4Col.vue' /* webpackChunkName: "components/common-footer4-col" */).then(c => wrapFunctional(c.default || c))
export const CommonGridCard = () => import('../../components/common/GridCard.vue' /* webpackChunkName: "components/common-grid-card" */).then(c => wrapFunctional(c.default || c))
export const CommonHeader = () => import('../../components/common/Header.vue' /* webpackChunkName: "components/common-header" */).then(c => wrapFunctional(c.default || c))
export const CommonHeaderCheckout = () => import('../../components/common/HeaderCheckout.vue' /* webpackChunkName: "components/common-header-checkout" */).then(c => wrapFunctional(c.default || c))
export const CommonLanguageSwitcher = () => import('../../components/common/LanguageSwitcher.vue' /* webpackChunkName: "components/common-language-switcher" */).then(c => wrapFunctional(c.default || c))
export const CommonMainSlider = () => import('../../components/common/MainSlider.vue' /* webpackChunkName: "components/common-main-slider" */).then(c => wrapFunctional(c.default || c))
export const CommonMarketingTopBanner = () => import('../../components/common/MarketingTopBanner.vue' /* webpackChunkName: "components/common-marketing-top-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonMenuBlock = () => import('../../components/common/MenuBlock.vue' /* webpackChunkName: "components/common-menu-block" */).then(c => wrapFunctional(c.default || c))
export const CommonMobileMenu = () => import('../../components/common/MobileMenu.vue' /* webpackChunkName: "components/common-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonProductCard = () => import('../../components/common/ProductCard.vue' /* webpackChunkName: "components/common-product-card" */).then(c => wrapFunctional(c.default || c))
export const CommonProductComplimentCard = () => import('../../components/common/ProductComplimentCard.vue' /* webpackChunkName: "components/common-product-compliment-card" */).then(c => wrapFunctional(c.default || c))
export const CommonResultProductCard = () => import('../../components/common/ResultProductCard.vue' /* webpackChunkName: "components/common-result-product-card" */).then(c => wrapFunctional(c.default || c))
export const CommonSideMenu = () => import('../../components/common/SideMenu.vue' /* webpackChunkName: "components/common-side-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonTopMenu = () => import('../../components/common/TopMenu.vue' /* webpackChunkName: "components/common-top-menu" */).then(c => wrapFunctional(c.default || c))
export const JourneyQuizBtnsBlock = () => import('../../components/journey-quiz/QuizBtnsBlock.vue' /* webpackChunkName: "components/journey-quiz-btns-block" */).then(c => wrapFunctional(c.default || c))
export const JourneyQuizListBlock = () => import('../../components/journey-quiz/QuizListBlock.vue' /* webpackChunkName: "components/journey-quiz-list-block" */).then(c => wrapFunctional(c.default || c))
export const JourneyQuizPagination = () => import('../../components/journey-quiz/QuizPagination.vue' /* webpackChunkName: "components/journey-quiz-pagination" */).then(c => wrapFunctional(c.default || c))
export const PopupsGlobalSearchPopup = () => import('../../components/popups/GlobalSearchPopup.vue' /* webpackChunkName: "components/popups-global-search-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsImageViewer = () => import('../../components/popups/ImageViewer.vue' /* webpackChunkName: "components/popups-image-viewer" */).then(c => wrapFunctional(c.default || c))
export const PopupsMarketingPopup = () => import('../../components/popups/MarketingPopup.vue' /* webpackChunkName: "components/popups-marketing-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsSimplePopup = () => import('../../components/popups/SimplePopup.vue' /* webpackChunkName: "components/popups-simple-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsSystemPopup = () => import('../../components/popups/SystemPopup.vue' /* webpackChunkName: "components/popups-system-popup" */).then(c => wrapFunctional(c.default || c))
export const SharedCheckoutBreadcrumbs = () => import('../../components/shared/CheckoutBreadcrumbs.vue' /* webpackChunkName: "components/shared-checkout-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const SharedFilterListItem = () => import('../../components/shared/FilterListItem.vue' /* webpackChunkName: "components/shared-filter-list-item" */).then(c => wrapFunctional(c.default || c))
export const SvgArrowRightSvg = () => import('../../components/svg/ArrowRightSvg.vue' /* webpackChunkName: "components/svg-arrow-right-svg" */).then(c => wrapFunctional(c.default || c))
export const SvgCaretRightSvg = () => import('../../components/svg/CaretRightSvg.vue' /* webpackChunkName: "components/svg-caret-right-svg" */).then(c => wrapFunctional(c.default || c))
export const SvgCarretRightSvg = () => import('../../components/svg/CarretRightSvg.vue' /* webpackChunkName: "components/svg-carret-right-svg" */).then(c => wrapFunctional(c.default || c))
export const SvgCloseSvg = () => import('../../components/svg/CloseSvg.vue' /* webpackChunkName: "components/svg-close-svg" */).then(c => wrapFunctional(c.default || c))
export const SvgCloseSvgBold = () => import('../../components/svg/CloseSvgBold.vue' /* webpackChunkName: "components/svg-close-svg-bold" */).then(c => wrapFunctional(c.default || c))
export const SvgExpandSvg = () => import('../../components/svg/ExpandSvg.vue' /* webpackChunkName: "components/svg-expand-svg" */).then(c => wrapFunctional(c.default || c))
export const SvgNextArrowSvg = () => import('../../components/svg/NextArrowSvg.vue' /* webpackChunkName: "components/svg-next-arrow-svg" */).then(c => wrapFunctional(c.default || c))
export const SvgPinSvg = () => import('../../components/svg/PinSvg.vue' /* webpackChunkName: "components/svg-pin-svg" */).then(c => wrapFunctional(c.default || c))
export const SvgPrevArrowSvg = () => import('../../components/svg/PrevArrowSvg.vue' /* webpackChunkName: "components/svg-prev-arrow-svg" */).then(c => wrapFunctional(c.default || c))
export const SvgUserSvg = () => import('../../components/svg/UserSvg.vue' /* webpackChunkName: "components/svg-user-svg" */).then(c => wrapFunctional(c.default || c))
export const BlocksCustomBlocksPdpServicesBlock = () => import('../../components/blocks/CustomBlocks/PdpServicesBlock.vue' /* webpackChunkName: "components/blocks-custom-blocks-pdp-services-block" */).then(c => wrapFunctional(c.default || c))
export const CommonCartDrawer = () => import('../../components/common/CartDrawer/CartDrawer.vue' /* webpackChunkName: "components/common-cart-drawer" */).then(c => wrapFunctional(c.default || c))
export const CommonCartProductCard = () => import('../../components/common/CartDrawer/CartProductCard.vue' /* webpackChunkName: "components/common-cart-product-card" */).then(c => wrapFunctional(c.default || c))
export const PagesArticlesArticleSlider = () => import('../../components/pages/articles/ArticleSlider.vue' /* webpackChunkName: "components/pages-articles-article-slider" */).then(c => wrapFunctional(c.default || c))
export const PagesArticlesList = () => import('../../components/pages/articles/ArticlesList.vue' /* webpackChunkName: "components/pages-articles-list" */).then(c => wrapFunctional(c.default || c))
export const PagesArticlesLatestArticleCard = () => import('../../components/pages/articles/LatestArticleCard.vue' /* webpackChunkName: "components/pages-articles-latest-article-card" */).then(c => wrapFunctional(c.default || c))
export const PagesArticlesLatestArticles = () => import('../../components/pages/articles/LatestArticles.vue' /* webpackChunkName: "components/pages-articles-latest-articles" */).then(c => wrapFunctional(c.default || c))
export const PagesArticlesRelatedPosts = () => import('../../components/pages/articles/RelatedPosts.vue' /* webpackChunkName: "components/pages-articles-related-posts" */).then(c => wrapFunctional(c.default || c))
export const PagesCartDetails = () => import('../../components/pages/cart/CartDetails.vue' /* webpackChunkName: "components/pages-cart-details" */).then(c => wrapFunctional(c.default || c))
export const PagesCartMobileProductCard = () => import('../../components/pages/cart/CartMobileProductCard.vue' /* webpackChunkName: "components/pages-cart-mobile-product-card" */).then(c => wrapFunctional(c.default || c))
export const PagesCheckoutDetails = () => import('../../components/pages/checkout/CheckoutDetails.vue' /* webpackChunkName: "components/pages-checkout-details" */).then(c => wrapFunctional(c.default || c))
export const PagesCheckoutDiscount = () => import('../../components/pages/checkout/CheckoutDiscount.vue' /* webpackChunkName: "components/pages-checkout-discount" */).then(c => wrapFunctional(c.default || c))
export const PagesCheckoutProductCard = () => import('../../components/pages/checkout/CheckoutProductCard.vue' /* webpackChunkName: "components/pages-checkout-product-card" */).then(c => wrapFunctional(c.default || c))
export const PagesCheckoutFirstStep = () => import('../../components/pages/checkout/FirstStep.vue' /* webpackChunkName: "components/pages-checkout-first-step" */).then(c => wrapFunctional(c.default || c))
export const PagesCheckoutOrderCompleteProducts = () => import('../../components/pages/checkout/OrderCompleteProducts.vue' /* webpackChunkName: "components/pages-checkout-order-complete-products" */).then(c => wrapFunctional(c.default || c))
export const PagesCheckoutOrderForm = () => import('../../components/pages/checkout/OrderForm.vue' /* webpackChunkName: "components/pages-checkout-order-form" */).then(c => wrapFunctional(c.default || c))
export const PagesCheckoutSecondStep = () => import('../../components/pages/checkout/SecondStep.vue' /* webpackChunkName: "components/pages-checkout-second-step" */).then(c => wrapFunctional(c.default || c))
export const PagesCheckoutStripeCheckoutForm = () => import('../../components/pages/checkout/StripeCheckoutForm.vue' /* webpackChunkName: "components/pages-checkout-stripe-checkout-form" */).then(c => wrapFunctional(c.default || c))
export const PagesCheckoutSummary = () => import('../../components/pages/checkout/Summary.vue' /* webpackChunkName: "components/pages-checkout-summary" */).then(c => wrapFunctional(c.default || c))
export const PagesIndexTutorial = () => import('../../components/pages/index/Tutorial.vue' /* webpackChunkName: "components/pages-index-tutorial" */).then(c => wrapFunctional(c.default || c))
export const PagesLoginForm = () => import('../../components/pages/login/LoginForm.vue' /* webpackChunkName: "components/pages-login-form" */).then(c => wrapFunctional(c.default || c))
export const PagesLoginRecoverPassword = () => import('../../components/pages/login/RecoverPassword.vue' /* webpackChunkName: "components/pages-login-recover-password" */).then(c => wrapFunctional(c.default || c))
export const PagesLoginRegisterForm = () => import('../../components/pages/login/RegisterForm.vue' /* webpackChunkName: "components/pages-login-register-form" */).then(c => wrapFunctional(c.default || c))
export const PagesOrderItem = () => import('../../components/pages/order/OrderItem.vue' /* webpackChunkName: "components/pages-order-item" */).then(c => wrapFunctional(c.default || c))
export const PagesOrderProductList = () => import('../../components/pages/order/OrderProductList.vue' /* webpackChunkName: "components/pages-order-product-list" */).then(c => wrapFunctional(c.default || c))
export const PagesOrderSummary = () => import('../../components/pages/order/OrderSummary.vue' /* webpackChunkName: "components/pages-order-summary" */).then(c => wrapFunctional(c.default || c))
export const PagesOrderTrackingComponent = () => import('../../components/pages/order-tracking/TrackingComponent.vue' /* webpackChunkName: "components/pages-order-tracking-component" */).then(c => wrapFunctional(c.default || c))
export const PagesProductFilterBtn = () => import('../../components/pages/product/FilterBtn.vue' /* webpackChunkName: "components/pages-product-filter-btn" */).then(c => wrapFunctional(c.default || c))
export const PagesProductFilterSelect = () => import('../../components/pages/product/FilterSelect.vue' /* webpackChunkName: "components/pages-product-filter-select" */).then(c => wrapFunctional(c.default || c))
export const PagesProductFilterSlider = () => import('../../components/pages/product/FilterSlider.vue' /* webpackChunkName: "components/pages-product-filter-slider" */).then(c => wrapFunctional(c.default || c))
export const PagesProductInDivConfigurator = () => import('../../components/pages/product/InDivConfigurator.vue' /* webpackChunkName: "components/pages-product-in-div-configurator" */).then(c => wrapFunctional(c.default || c))
export const PagesProductPerfectComplimentBlock = () => import('../../components/pages/product/PerfectComplimentBlock.vue' /* webpackChunkName: "components/pages-product-perfect-compliment-block" */).then(c => wrapFunctional(c.default || c))
export const PagesProductAccordion = () => import('../../components/pages/product/ProductAccordion.vue' /* webpackChunkName: "components/pages-product-accordion" */).then(c => wrapFunctional(c.default || c))
export const PagesProductDetails = () => import('../../components/pages/product/ProductDetails.vue' /* webpackChunkName: "components/pages-product-details" */).then(c => wrapFunctional(c.default || c))
export const PagesProductMediaDesktop = () => import('../../components/pages/product/ProductMediaDesktop.vue' /* webpackChunkName: "components/pages-product-media-desktop" */).then(c => wrapFunctional(c.default || c))
export const PagesProductMediaMobileSticky = () => import('../../components/pages/product/ProductMediaMobileSticky.vue' /* webpackChunkName: "components/pages-product-media-mobile-sticky" */).then(c => wrapFunctional(c.default || c))
export const PagesProductPurchaseForm = () => import('../../components/pages/product/ProductPurchaseForm.vue' /* webpackChunkName: "components/pages-product-purchase-form" */).then(c => wrapFunctional(c.default || c))
export const PagesProductTabs = () => import('../../components/pages/product/ProductTabs.vue' /* webpackChunkName: "components/pages-product-tabs" */).then(c => wrapFunctional(c.default || c))
export const PagesShippingAddressesForm = () => import('../../components/pages/shipping-addresses/ShippingAddressesForm.vue' /* webpackChunkName: "components/pages-shipping-addresses-form" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
