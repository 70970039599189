
import useMetaIcons from "~/mixins/useMetaIcons";

export default {
  components: {
    SystemPopup: () => import("~/components/popups/SystemPopup"),
    AppFooter: () => import("~/components/common/AppFooter"),
    Header: () => import("@/components/common/Header.vue"),
    SideMenu: () => import("~/components/common/SideMenu.vue"),
    MarketingPopup: () => import("~/components/popups/MarketingPopup.vue"),
  },
  mixins: [useMetaIcons],
};
