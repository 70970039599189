import General from "@/api/general";
import Pages from "@/api/pages";
import Products from "@/api/products";
import Public from "@/api/public";
import Blog from "@/api/blog";
import Cart from "@/api/cart";
import Wishlist from "@/api/wishlist";
import Comparison from "@/api/comparison";
import Auth from "@/api/auth";
import User from "@/api/user";
import Orders from "@/api/orders";
import Events from "@/api/events";

export default ({ app, $axios }, inject) => {
  const factories = {
    general: General($axios),
    pages: Pages($axios),
    products: Products($axios),
    public: Public($axios),
    blog: Blog($axios),
    cart: Cart($axios),
    wishlist: Wishlist($axios),
    comparison: Comparison($axios),
    auth: Auth($axios),
    user: User($axios),
    orders: Orders($axios),
    events: Events($axios),
  };

  // Inject $api
  inject("api", factories);
};
