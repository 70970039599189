export default (axios) => ({
  /**
   * Get bootstrap
   *
   * @link https://devapi.omara.sk/apidoc/#api-Public-GetApiV1Bootstrap
   * @returns {*}
   */
  getBootstrap() {
    return axios.get("/bootstrap");
  },
  /**
   * Get all filters
   *
   * @link https://devapi.omara.sk/apidoc/#api-Events-GetApiV1Filters
   * @param data
   * @returns {*}
   */
  getFilters() {
    return axios.get("/filters");
  },
  /**
   * Get languages
   *
   * @link https://devapi.omara.sk/apidoc/#api-Languages-GetApiV1Languages
   * @returns {*}
   */
  getLanguages() {
    return axios.get("/languages");
  },

  /**
   * Get popups
   *
   * @link https://devapi.omara.sk/apidoc/#api-Popups-GetApiV1Popups
   * @param params
   * @returns {*}
   */
  getPopups(params) {
    return axios.get("/popups", { params });
  },

  /**
   * Get discount campaigns
   *
   * @link https://devapi.omara.sk/apidoc/#api-Admin_Campaign_Discount
   * @returns {*}
   */
  getDiscountCampaigns() {
    return axios.get("/campaign-discount");
  },
  /**
   * Get Payment Gateways List
   *
   * @link https://devapi.omara.sk/apidoc/#api-Payment_Gateway-GetApiV1PaymentGateways
   * @param data
   * @returns {*}
   */
  getPaymentGateways() {
    return axios.get("/payment-gateways");
  },

  /**
   * Get Payment Methods List
   *
   * @link https://devapi.omara.sk/apidoc/#api-Payment_Method-GetApiV1PaymentMethods
   * @returns {*}
   */
  getPaymentMethodsList() {
    return axios.get("/payment-methods");
  },
});
