import { render, staticRenderFns } from "./CatalogBlock.vue?vue&type=template&id=4ac3141e&scoped=true&"
import script from "./CatalogBlock.vue?vue&type=script&lang=js&"
export * from "./CatalogBlock.vue?vue&type=script&lang=js&"
import style0 from "./CatalogBlock.vue?vue&type=style&index=0&id=4ac3141e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac3141e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiSpinner: require('/var/teamcity-builds/Omara_Staging_Builds_Frontend/components/UI/UiSpinner.vue').default,UiPagination: require('/var/teamcity-builds/Omara_Staging_Builds_Frontend/components/UI/UiPagination.vue').default})
